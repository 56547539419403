"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackMatomoEvent = void 0;
var matomo_lib_1 = require("./matomo-lib");
var _mtm = window['_mtm'] = window['_mtm'] || [];
_mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
// For now, we need to fake the Shadow DOM with screenshots anyway, so this is not needed
// See NIG-219
// if (!(window as any).njBookingReady && document.querySelector('nj-root,nj-search-root,nj-account-root')) {
//     // Delay heatmap until nj booking platform is ready
//     const _paq = (window as any)._paq = (window as any)._paq || [];
//     _paq.push(['HeatmapSessionRecording::disable']);
// }
function addMatomoClickListeners() {
    var connectingTrainsInformationAccordionElement = document.getElementById("connectingTrainsInformationAccordion");
    if (connectingTrainsInformationAccordionElement !== undefined && connectingTrainsInformationAccordionElement !== null) {
        connectingTrainsInformationAccordionElement.addEventListener("click", function () {
            trackMatomoEvent({ "event": "nj.searchConnection.informationAccordion.expanded" });
        });
    }
}
function trackMatomoEvent(event) {
    var matomo = window._mtm || [];
    if (matomo !== undefined && matomo !== null) {
        matomo === null || matomo === void 0 ? void 0 : matomo.push(event);
    }
}
exports.trackMatomoEvent = trackMatomoEvent;
addMatomoClickListeners();
var containerId = document.currentScript.dataset.containerId;
(0, matomo_lib_1.waitForConsent)(function () { return (0, matomo_lib_1.loadMatomoScript)("https://analytics-pv.oebb.at/matomo/js/container_".concat(containerId, ".js")); });
